<template>
  <b-container fluid>
    <!-- WELCOME -->
    <BaseWelcome :name="user.name" :greeting-message="$t('welcome')" />

    <BaseGuideline
      :title="$t('guideline_title')"
      :message="$t('guideline_message')"
      icon="DownloadIcon"
    />

    <!-- TASKS / DELIVERIES TODAY -->
    <BaseTask
      :counter="taskTodayCount"
      :title="$t('tasktoday_title')"
      :description="$t('tasktoday_description')"
    />

    <!-- PERFORMANCE RATING -->
    <BasePerformance
      :value="value"
      :title="$t('performance_title')"
      :message="$t('performance_message')"
    />

    <!-- MILESTONES -->
    <BaseMilestone
      :milestones="milestones"
      :title="$t('milestones_title')"
      :description="$t('milestones_description')"
    />

    <b-modal
      id="policy-modal"
      title="Policy Notice"
      hide-footer
      hide-header
      size="lg"
      no-close-on-backdrop
    >
      <div class="p-2">
        <h1 class="text-primary">Terms and Conditions</h1>
        <b-row class="mb-2 mt-3">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true',
                  'Refund Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Refund Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true',
                  'Data Privacy Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Data Privacy Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true',
                  'Rules & Conduct Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Rules & Conduct Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true',
                  'Privacy Terms Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Privacy Terms Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-end align-items-center">
          <b-col cols="auto" class="d-flex align-items-center">
            <span
              @click="rejectPolicy"
              class="text-primary"
              style="cursor: pointer"
            >
              Cancel
            </span>
          </b-col>
          <b-col cols="auto">
            <b-button variant="primary" @click="acceptPolicy">Accept</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-model="showModal" :title="modalTitle" size="lg" hide-footer>
      <iframe
        :src="docUrl"
        width="100%"
        height="750px"
        frameborder="0"
      ></iframe>
    </b-modal>
  </b-container>
</template>

<script>
import { getUserData } from "@/auth/utils";
import axios from "@axios";
import useJwt from '@/auth/jwt/useJwt'
import {
  BaseWelcome,
  BaseTask,
  BasePerformance,
  BaseMilestone,
  BaseGuideline,
} from "@/components";

export default {
  name: "MyDashboard",
  components: {
    BaseWelcome,
    BaseTask,
    BasePerformance,
    BaseMilestone,
    BaseGuideline,
  },
  data() {
    return {
      value: 0,
      milestones: [],
      taskTodayCount: 0,
      deliveries: 0,
      user: { name: "Name" },
      policy: null,
      showModal: false,
      docUrl: '',
      modalTitle: '',
    };
  },
  created() {
    const self = this;
    const headers = { Accept: "application/json" };
    this.user = getUserData();
    this.tasksTodayCount();
    this.checkPolicy()
    axios
      .get(`${this.$appURL}milestone`, { headers })
      .catch(() => {})
      .then((response) => {
        self.milestones = response.data.data;
        self.deliveries = response.data.count;
        if (self.deliveries <= 10) {
          self.value = 1;
        } else if (self.deliveries >= 11 || self.deliveries <= 20) {
          self.value = 2;
        } else if (self.deliveries >= 21 || self.deliveries <= 30) {
          self.value = 3;
        } else if (self.deliveries >= 31 || self.deliveries <= 50) {
          self.value = 4;
        } else {
          self.value = 5;
        }
      });
  },
  methods: {
    async tasksTodayCount() {
      const response = await axios.get("smarttruck-dashboard");
      if (response.status === 200) {
        this.taskTodayCount = response.data.tasksToday;
      }
    },
    openModal(url, title) {
      this.docUrl = url
      this.modalTitle = title
      this.showModal = true
    },
    checkPolicy() {
      // const policy = JSON.parse(localStorage.getItem("policy"));
      const policy = 1
      this.policy = policy
    },
    acceptPolicy() {
      // Remove the policy from storage
      localStorage.removeItem('policy')

      // Hide the modal
      this.$bvModal.hide('policy-modal')
    },
    rejectPolicy() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })
    },
  },
  watch: {
    policy(newPolicy) {
      if (newPolicy === 1) {
        this.$bvModal.show('policy-modal')
      }
    },
  },
};
</script>
<style lang="scss">

.custom-button {
  font-weight: bold;
  font-size: 1.2em;
  border-color: lightgray !important;
}
</style>
